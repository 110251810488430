.root {
  padding: calc(var(--mui-spacing) * 6) 0;
}

.container {
  padding: calc(var(--mui-spacing) * 2) calc(var(--mui-spacing) * 4);
  border-radius: calc(var(--mui-spacing) * 2);
  border-bottom-left-radius: 0;
  background: var(--mui-palette-mono-dark);
}

.card {
  display: flex;
  flex-direction: column;
  gap: calc(var(--mui-spacing) * 2);

  background-color: var(--design-color-common-white);
  padding: calc(var(--mui-spacing) * 3);
  border-radius: calc(var(--mui-spacing) * 3);
  border-bottom-left-radius: 0;
}

.button {
  align-self: flex-end;
  border-radius: var(--design-borderRadius-sm);
}

@media (--mui-breakpoint-md-up) {
  .container {
    min-height: calc(var(--mui-spacing) * 36);
    padding: calc(var(--mui-spacing) * 3) calc(var(--mui-spacing) * 4);
    background: var(--mui-palette-mono-dark) url('../../images/SafetyTip.webp')
      no-repeat scroll center/55%;
    background-position-x: 100%;
  }

  .card {
    max-width: 50%;
  }
}

@media (--mui-breakpoint-lg-up) {
  .container {
    padding: calc(var(--mui-spacing) * 4) calc(var(--mui-spacing) * 6);
  }
}

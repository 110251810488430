.root {
  position: relative;
  margin-top: calc(var(--mui-spacing) * 4);
}

.scrollContainer {
  --x-gallery-item-count: 4;
  --x-gallery-item-width: 300px;
  --x-gallery-item-spacing: calc(var(--mui-spacing) * 2);

  /* snap mandatory on horizontal axis  */
  scroll-snap-type: x mandatory;

  overflow-x: scroll;
  overflow-y: hidden;

  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(
    var(--x-gallery-item-count),
    var(--x-gallery-item-width)
  );
  gap: var(--x-gallery-item-spacing);

  /* Enable Safari touch scrolling physics which is needed for scroll snap */
  -webkit-overflow-scrolling: touch;

  scroll-behavior: smooth;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollContainer > * {
  scroll-snap-align: start;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollContainer::-webkit-scrollbar {
  display: none;
}

.goForwardButton,
.goBackButton {
  position: absolute;

  top: 50%;
  transform: translateY(-50%);

  height: calc(var(--mui-spacing) * 4);
  width: calc(var(--mui-spacing) * 4);

  background-color: rgba(255, 255, 255, 0.3);

  z-index: 1;
}

.goForwardButton {
  right: var(--mui-spacing);
}

.goBackButton {
  left: var(--mui-spacing);
}

.root > :global(.MuiButton-startIcon) {
  margin: 0;
}

.root {
  border-radius: calc(var(--mui-spacing) * 0.5);
}

@media (--mui-breakpoint-sm-up) {
  .root {
    grid-column: 3;
    grid-row: 1 / 3;
    min-width: calc(var(--mui-spacing) * 15);
  }
}

@media (--mui-breakpoint-md-up) {
  .root {
    grid-column: unset;
    grid-row: unset;
  }
}

.root {
  padding-block: calc(var(--mui-spacing) * 8) calc(var(--mui-spacing) * 6);
  background-color: var(--design-color-secondary-main);
  color: white;

  font-family: var(--design-headerFontFamily);
}

.logoKn > svg {
  fill: var(--design-color-common-white);
  height: 70px;
  width: 241px;
  margin-block-end: var(--mui-spacing);
}

.siteLinksContainer {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--mui-spacing);
}

.linkColumn {
  display: flex;
  flex-direction: column;
  gap: var(--mui-spacing);
}

.socialIcon {
  background-color: var(--design-color-common-white);
  border-radius: 50%;
  box-sizing: content-box;
  padding: var(--mui-spacing);
}

.socialNetworkLinks {
  display: flex;
  gap: calc(var(--mui-spacing) * 1.5);
  text-align: center;
  margin-bottom: calc(var(--mui-spacing) * 2);
}

@media (--mui-breakpoint-sm-up) {
  .socialNetworkLinks {
    text-align: unset;
  }
}

@media (--mui-breakpoint-md-up) {
  .siteLinksContainer {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: calc(var(--mui-spacing) * 7);
  }

  .socialNetworkLinks {
    margin-bottom: 0;
  }
}

.root {
  background-color: var(--design-color-tertiary-accent);
  padding: calc(var(--mui-spacing) * 8) 0;
}

.header {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.readMore {
  display: none;
}

.card {
  background-color: var(--design-color-common-white);
}

@media (--mui-breakpoint-sm-up) {
  .readMore {
    display: initial;
  }
}

.root {
  height: 40px;
}

.borderless fieldset {
  border: none;
}

.noFloatingLabel label[data-shrink='true'] {
  display: none;
}

.noFloatingLabel fieldset span {
  display: none;
}

.placeHolder {
  transform: translate(1px, 0) scale(1);
}

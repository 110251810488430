.root {
  display: flex;
  flex-direction: column;
  padding-block: calc(var(--mui-spacing) * 8);
}

.content {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: calc(var(--mui-spacing) * 5) var(--mui-spacing);
  justify-content: space-between;
}

.title {
  margin-left: calc(var(--mui-spacing) * 1);
  margin-bottom: calc(var(--mui-spacing) * 1.5);
}

.links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--mui-spacing);
}

@media (--mui-breakpoint-md-down) {
  .content {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
  }
}

@media (--mui-breakpoint-sm-down) {
  .content {
    grid-template-columns: 1fr;
  }
}

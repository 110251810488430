.root {
  background-color: var(--design-color-secondary-light);
  padding-bottom: calc(var(--mui-spacing) * 4);
}

.title {
  padding: calc(var(--mui-spacing) * 3) 0;
  color: var(--design-color-secondary-contrastText);
}

.generationKamernetEn,
.generationKamernetNl {
  height: 128px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: scroll;
}

.generationKamernetEn {
  background-image: url('../../images/GenerationKamernetEn.webp');
}

.generationKamernetNl {
  background-image: url('../../images/GenerationKamernetNl.webp');
}

.searchBarPaper {
  background: var(--design-color-secondary-main);
}

@media (--mui-breakpoint-sm-up) {
  .root {
    min-height: 420px;
    background: var(--design-color-secondary-light);
    background-position-y: 15%;
    display: grid;
    align-items: center;
  }

  .sectionContainer {
    display: grid;
    gap: calc(var(--mui-spacing) * 2);
  }

  .title {
    padding: 0;
  }
}

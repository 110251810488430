.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: calc(var(--mui-spacing) * 2);
  background-color: var(--design-color-common-white);
  border-radius: var(--mui-spacing);
  padding: calc(var(--mui-spacing));
  border: 1px solid var(--design-color-mono-main);
}

.inputContainer {
  display: grid;
  align-items: start;
  border-radius: var(--mui-spacing);
  box-sizing: border-box;
  background-color: var(--design-color-common-white);
}

.locationAndRadiusContainer {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  padding-left: var(--mui-spacing);
}

@media (--mui-breakpoint-sm-up) {
  .inputContainer {
    box-shadow: none;
    border: none;
  }

  .listingTypesInput,
  .minSizeInput {
    border-left: 1px solid var(--design-color-mono-main);
    border-radius: 0;
    padding: 0 calc(var(--mui-spacing) * 1.5);
  }
}

@media (--mui-breakpoint-md-up) {
  .root {
    grid-template-columns: 4fr 3fr 2fr 2fr auto;
    grid-template-rows: 1fr;
    gap: 0;
    border: 1px solid var(--design-color-mono-main);
  }

  .maxRentInput {
    border-left: 1px solid var(--design-color-mono-main);
    border-radius: 0;
  }

  .maxRentInput,
  .minSizeInput {
    grid-template-columns: 1fr;
    padding: 0 calc(var(--mui-spacing) * 1.5);
    min-height: calc(var(--mui-spacing) * 5);
    display: flex;
    justify-content: center;
  }
}

.root {
  background-color: var(--design-color-primary-accent);
  padding: calc(var(--mui-spacing) * 8) 0;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--mui-spacing) * 4);
}

.welcome {
  display: flex;
  flex-direction: column;
}

.welcomeTitle {
  margin: calc(var(--mui-spacing) * 2) 0;
}

.statements {
  display: flex;
  gap: calc(var(--mui-spacing) * 2);
}

.statementBlock {
  flex: auto;
}

.statementElement:nth-of-type(even) {
  margin-top: calc(var(--mui-spacing) * 3);
}

.card {
  background-color: var(--design-color-common-white);
  padding: calc(var(--mui-spacing) * 2) calc(var(--mui-spacing) * 3);
  margin-top: calc(var(--mui-spacing) * 4);
  border-radius: calc(var(--mui-spacing) * 2);
  box-shadow: 0 0 calc(var(--mui-spacing) * 2) 0 rgb(0, 22, 63, 0.15);
}

.cardText {
  margin-top: calc(var(--mui-spacing) * 2);
}

.cardButton {
  color: var(--design-color-common-white);
  background-color: var(--design-color-primary-main);
  margin-top: calc(var(--mui-spacing) * 2);
}

@media (--mui-breakpoint-md-up) {
  .container {
    flex-wrap: nowrap;
  }

  .card {
    margin-top: 0;
  }

  .card,
  .welcome {
    flex: 1;
  }
}

.root {
  display: flex;
  align-items: center;
  gap: var(--mui-spacing);
}

.drawerLogoutButton {
  border: 0;
  background: none;
  padding: 0;
  color: var(--design-color-text-primary);
  text-align: left;
  width: 100%;
  line-height: inherit;
  font-weight: var(--mui-typography-h6-fontWeight);
  font-family: var(--mui-typography-h6-fontFamily);
  font-size: var(--mui-typography-h6-fontSize);
}

.drawer {
  list-style: none;
  padding: calc(var(--mui-spacing) * 1.5) calc(var(--mui-spacing) * 3);
  margin: 0;
}

.drawer > li {
  margin-top: calc(var(--mui-spacing) * 1.5);
}

.drawer > li > a {
  display: flex;
  gap: calc(var(--mui-spacing) * 1.5);
  align-items: center;
  font-weight: var(--mui-typography-h6-fontWeight);
  font-family: var(--mui-typography-h6-fontFamily);
  font-size: var(--mui-typography-h6-fontSize);
}

.drawerHead {
  display: flex;
  align-items: center;
  flex-flow: row-reverse wrap;
  gap: var(--mui-spacing);
}

.drawerProfileInfo {
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: calc(var(--mui-spacing) * 1.5);
}

.drawerDivider {
  height: 1px;
  background-color: var(--design-color-secondary-light);
}

@media (--mui-breakpoint-sm-up) {
  .root {
    gap: calc(var(--mui-spacing) * 2);
  }
}

.root {
  line-height: 0;
}

.root > svg {
  display: flex;
  align-items: center;
  height: 41px;
  width: 141px;
}

@media (--mui-breakpoint-md-up) {
  .root {
    margin-left: var(--mui-spacing);
  }
}

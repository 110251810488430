.root {
  padding-block: calc(var(--mui-spacing) * 8);
}

.title,
.subtitle {
  padding-block: calc(var(--mui-spacing) * 2);
}

.paragraph {
  padding-block: var(--mui-spacing);
}

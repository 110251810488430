.root {
  background-color: var(--design-color-secondary-main);
}

.container {
  display: flex;
  align-items: center;
}

.logo {
  line-height: 0;
}

.logo > svg {
  color: var(--mui-palette-neutral-light);
  margin-block: 10px;
  height: 41px;
  width: 141px;
}

@media (--mui-breakpoint-md-up) {
  .logo {
    margin-left: var(--mui-spacing);
  }
}

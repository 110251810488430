.root {
  background-color: var(--design-color-secondary-light);
}

.sectionContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: calc(var(--mui-spacing) * 2);
}

.rentButton {
  border-radius: var(--design-borderRadius-xs);
  background-color: var(--design-color-primary-accent);
}

.rentOutButton,
.rentButton {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media (--mui-breakpoint-sm-up) {
  .root {
    display: none;
  }
}

.root {
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  min-height: 100vh;
  position: relative;
}

.root > main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.pageTitle {
  padding: 1.75rem 0 0.75rem;
}

.root {
  display: flex;
  align-items: center;
}

.navLink {
  display: flex;
  align-items: center;
  height: 100%;
  padding-inline: calc(var(--mui-spacing) * 3);
  align-self: stretch;
  transition: all 300ms ease;
}

.navLink:hover {
  background-color: var(--design-color-primary-accent);
}

.searchForRoomLink {
  gap: calc(var(--mui-spacing) * 1.5);
}

.searchForRoomLink > svg {
  color: var(--design-color-secondary-light);
}

.centralSection {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
}

.centralSection > a {
  margin-inline: var(--mui-spacing);
}

.myAdvertsLink {
  display: none;
}

.myImage {
  display: flex;
  align-items: center;
  padding-inline: calc(var(--mui-spacing) * 1.5) calc(var(--mui-spacing) * 0.5);
}

.myImage > span {
  border-radius: var(--design-borderRadius-lg);
}

.popoverOpenButton {
  gap: calc(var(--mui-spacing) * 1.5);
  margin-left: calc(var(--mui-spacing) * 0.5);
}

.popoverContent {
  list-style: none;
  padding: var(--mui-spacing) calc(var(--mui-spacing) * 3);
  margin: 0;
}

.popoverBorder {
  border-top: 1px solid var(--design-color-mono-main);
}

.popoverContent > li {
  padding: calc(var(--mui-spacing) * 1.5) var(--mui-spacing);
  font-size: var(--mui-typography-h6-fontSize);
}

.popoverContent > li > a,
.popoverContent > li > button {
  display: flex;
  align-items: center;
  gap: calc(var(--mui-spacing) * 3);
}

.popoverContent > li > a:hover {
  text-decoration: underline;
}

.languageContainer {
  display: flex;
  align-items: center;
  gap: var(--mui-spacing);
}

.popoverLogoutButton {
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  color: var(--design-color-text-primary);
  text-align: left;
  width: 100%;
  line-height: inherit;
  font-size: var(--mui-typography-h6-fontSize);
}

.popoverLogoutButton:hover {
  text-decoration: underline;
}

@media (--mui-breakpoint-lg-up) {
  .myAdvertsLink {
    display: flex;
  }

  .myAdvertsLink + .divider {
    display: block;
  }
}

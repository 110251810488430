.root {
  height: 100%;
  background-color: transparent;
}

.media {
  aspect-ratio: 16 / 9;

  position: relative;

  border-radius: calc(var(--mui-spacing) * 1);
  border-bottom-left-radius: 0;
}

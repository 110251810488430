/*******************************************************************************
 * Margins
 ******************************************************************************/

.margin_auto {
  margin: auto !important;
}

.margin_0 {
  margin: 0 !important;
}

.margin_1 {
  margin: var(--mui-spacing);
}

.margin_2 {
  margin: calc(var(--mui-spacing) * 2);
}

.margin_3 {
  margin: calc(var(--mui-spacing) * 3);
}

.margin_4 {
  margin: calc(var(--mui-spacing) * 4);
}

.margin_5 {
  margin: calc(var(--mui-spacing) * 5);
}

.margin_right_auto {
  margin-right: auto !important;
}

.margin_right_0 {
  margin-right: 0 !important;
}

.margin_right_1 {
  margin-right: var(--mui-spacing);
}

.margin_right_2 {
  margin-right: calc(var(--mui-spacing) * 2);
}

.margin_right_3 {
  margin-right: calc(var(--mui-spacing) * 3);
}

.margin_right_4 {
  margin-right: calc(var(--mui-spacing) * 4);
}

.margin_right_5 {
  margin-right: calc(var(--mui-spacing) * 5);
}

.margin_left_auto {
  margin-left: auto !important;
}

.margin_left_0 {
  margin-left: 0 !important;
}

.margin_left_1 {
  margin-left: var(--mui-spacing);
}

.margin_left_2 {
  margin-left: calc(var(--mui-spacing) * 2);
}

.margin_left_3 {
  margin-left: calc(var(--mui-spacing) * 3);
}

.margin_top_auto {
  margin-top: auto !important;
}

.margin_top_0 {
  margin-top: 0 !important;
}

.margin_top_1 {
  margin-top: var(--mui-spacing);
}

.margin_top_2 {
  margin-top: calc(var(--mui-spacing) * 2);
}

.margin_top_3 {
  margin-top: calc(var(--mui-spacing) * 3);
}

.margin_top_4 {
  margin-top: calc(var(--mui-spacing) * 4);
}

.margin_top_5 {
  margin-top: calc(var(--mui-spacing) * 5);
}

.margin_bottom_auto {
  margin-bottom: auto !important;
}

.margin_bottom_0 {
  margin-bottom: 0 !important;
}

.margin_bottom_1 {
  margin-bottom: var(--mui-spacing);
}

.margin_bottom_2 {
  margin-bottom: calc(var(--mui-spacing) * 2);
}

.margin_bottom_3 {
  margin-bottom: calc(var(--mui-spacing) * 3);
}

.margin_bottom_4 {
  margin-bottom: calc(var(--mui-spacing) * 4);
}

.margin_bottom_5 {
  margin-bottom: calc(var(--mui-spacing) * 5);
}

.margin_block_auto {
  margin-block: auto !important;
}

.margin_block_0 {
  margin-block: 0 !important;
}

.margin_block_1 {
  margin-block: var(--mui-spacing);
}

.margin_block_2 {
  margin-block: calc(var(--mui-spacing) * 2);
}

.margin_block_3 {
  margin-block: calc(var(--mui-spacing) * 3);
}

.margin_block_4 {
  margin-block: calc(var(--mui-spacing) * 4);
}

.margin_block_5 {
  margin-block: calc(var(--mui-spacing) * 5);
}

.margin_inline_auto {
  margin-inline: auto !important;
}

.margin_inline_0 {
  margin-inline: 0 !important;
}

.margin_inline_1 {
  margin-inline: var(--mui-spacing);
}

.margin_inline_2 {
  margin-inline: calc(var(--mui-spacing) * 2);
}

.margin_inline_3 {
  margin-inline: calc(var(--mui-spacing) * 3);
}

.margin_inline_4 {
  margin-inline: calc(var(--mui-spacing) * 4);
}

.margin_inline_5 {
  margin-inline: calc(var(--mui-spacing) * 5);
}

/*******************************************************************************
 * Paddings
 ******************************************************************************/

.padding_0 {
  padding: 0;
}

.padding_1 {
  padding: var(--mui-spacing);
}

.padding_2 {
  padding: calc(var(--mui-spacing) * 2);
}

.padding_3 {
  padding: calc(var(--mui-spacing) * 3);
}

.padding_4 {
  padding: calc(var(--mui-spacing) * 4);
}

.padding_5 {
  padding: calc(var(--mui-spacing) * 5);
}

.padding_right_0 {
  padding-right: 0;
}

.padding_right_1 {
  padding-right: var(--mui-spacing);
}

.padding_right_2 {
  padding-right: calc(var(--mui-spacing) * 2);
}

.padding_right_3 {
  padding-right: calc(var(--mui-spacing) * 3);
}

.padding_right_4 {
  padding-right: calc(var(--mui-spacing) * 4);
}

.padding_right_5 {
  padding-right: calc(var(--mui-spacing) * 5);
}

.padding_left_0 {
  padding-left: 0;
}

.padding_left_1 {
  padding-left: var(--mui-spacing);
}

.padding_left_2 {
  padding-left: calc(var(--mui-spacing) * 2);
}

.padding_left_3 {
  padding-left: calc(var(--mui-spacing) * 3);
}

.padding_left_4 {
  padding-left: calc(var(--mui-spacing) * 4);
}

.padding_left_5 {
  padding-left: calc(var(--mui-spacing) * 5);
}

.padding_top_0 {
  padding-top: 0;
}

.padding_top_1 {
  padding-top: var(--mui-spacing);
}

.padding_top_2 {
  padding-top: calc(var(--mui-spacing) * 2);
}

.padding_top_3 {
  padding-top: calc(var(--mui-spacing) * 3);
}

.padding_top_4 {
  padding-top: calc(var(--mui-spacing) * 4);
}

.padding_top_5 {
  padding-top: calc(var(--mui-spacing) * 5);
}

.padding_bottom_0 {
  padding-bottom: 0;
}

.padding_bottom_1 {
  padding-bottom: var(--mui-spacing);
}

.padding_bottom_2 {
  padding-bottom: calc(var(--mui-spacing) * 2);
}

.padding_bottom_3 {
  padding-bottom: calc(var(--mui-spacing) * 3);
}

.padding_bottom_4 {
  padding-bottom: calc(var(--mui-spacing) * 4);
}

.padding_bottom_5 {
  padding-bottom: calc(var(--mui-spacing) * 5);
}

.padding_block_0 {
  padding-block: 0;
}

.padding_block_1 {
  padding-block: var(--mui-spacing);
}

.padding_block_2 {
  padding-block: calc(var(--mui-spacing) * 2);
}

.padding_block_3 {
  padding-block: calc(var(--mui-spacing) * 3);
}

.padding_block_4 {
  padding-block: calc(var(--mui-spacing) * 4);
}

.padding_block_5 {
  padding-block: calc(var(--mui-spacing) * 5);
}

.padding_inline_0 {
  padding-inline: 0;
}

.padding_inline_1 {
  padding-inline: var(--mui-spacing);
}

.padding_inline_2 {
  padding-inline: calc(var(--mui-spacing) * 2);
}

.padding_inline_3 {
  padding-inline: calc(var(--mui-spacing) * 3);
}

.padding_inline_4 {
  padding-inline: calc(var(--mui-spacing) * 4);
}

.padding_inline_5 {
  padding-inline: calc(var(--mui-spacing) * 5);
}

/*******************************************************************************
 * Text
 ******************************************************************************/

.upperCase {
  text-transform: uppercase;
}

.sentenceCase {
  text-transform: capitalize;
}

.textCenter {
  text-align: center;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*******************************************************************************
 * Media
 ******************************************************************************/

@media (--mui-breakpoint-md-up) {
  .visibleOnMobile {
    display: none;
  }

  .hiddenOnDesktop {
    display: none;
  }
}

@media (--mui-breakpoint-md-down) {
  .visibleOnDesktop {
    display: none;
  }

  .hiddenOnMobile {
    display: none;
  }
}

/*******************************************************************************
 * Layouts
 ******************************************************************************/

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.displayNone {
  display: none;
}

.displayFlex {
  display: flex;
}

.flex {
  flex: 1;
}

/*******************************************************************************
 * Popover
 * @todo use mui style overrides after upgrading MUI to version 5.11.5 or higher
 ******************************************************************************/

:global(.MuiPopover-root).popoverBorderRadiusSmall :global(.MuiPopover-paper) {
  border-radius: var(--design-borderRadius-sm);
}

:global(.MuiPopover-root).popoverBorderRadiusMedium :global(.MuiPopover-paper) {
  border-radius: var(--design-borderRadius-md);
}

/*******************************************************************************
 * box shadows
 ******************************************************************************/

.shadow_1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

/*******************************************************************************
 * dividers
 ******************************************************************************/

.divider_1 {
  background-color: var(--design-color-secondary-light);
  height: 1px;
  width: 100%;
  overflow: hidden;
}

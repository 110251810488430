.root {
  background-color: var(--design-color-primary-accent);
  padding: calc(var(--mui-spacing) * 8) 0;
}

.container {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.readMore {
  text-align: right;
}

.root {
  padding: calc(var(--mui-spacing) * 6) 0;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: calc(var(--mui-spacing) * 2);
}

.viewAllHomes {
  margin-left: auto;
}
